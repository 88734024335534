/** @jsx jsx */
/* eslint-disable jsx-a11y/no-autofocus, react/jsx-no-target-blank */
import { jsx } from "theme-ui"

function ContactBanner() {

  return (
    <article
      sx={{
        backgroundColor: "contactUsBackground",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "paperHeadingColor",
        borderRadius: 3,
        py: 4,
        px: 4,
        mb: 6,
        position: "relative",
        zIndex: "3",
        textDecoration: "none",
        overflow: "hidden",
        width: "100%",
        display: "flex",
        flexDirection: ["column", "row"],
        outline: "none",
        boxShadow: "0 3px 8px 0 rgba(0,0,0,0.03)",
        color: "paperHeadingColor",
        
      }}
    >
      <div sx={{ flex: "6", px: [2, 0] }}>
        <header>
          <h3
            sx={{
              mt: 0,
              mb: 2,
              color: "inherit",
            }}
          >
            Need help?
          </h3>
        </header>
        <section
          sx={{
            color: "paperDescriptionColor",
          }}
        >
          Contact us: <b>414-777-0900</b>
          <br/>
          Monday through Friday, 8a.m.-5p.m. and Saturday 8a.m.-Noon.
        </section>
        <small
          sx={{
            color: "paperDescriptionColor",
          }}
        >
          
        </small>
      </div>
    </article>
  )
}

export default ContactBanner
