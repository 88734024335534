/** @jsx jsx */
import { jsx } from "theme-ui"

const Logo = props => (
  <svg
    sx={{
      height: props.size,
      width: props.size,
    }}
    viewBox="0 0 332 318"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill={props.color} d="M113.24,287.79c26.74-0.97,40.19-19.13,56.65-40.48c-28.41-33.68-52.36-66.43-78.76-101.04
      c-28.4,17.52-48.68,32.24-49.11,61.99C41.46,246.43,69.02,289.42,113.24,287.79 M128.69,7.61c-22.41,0-36.84,14.7-36.84,36.51
      c0,21.85,15.96,44.82,31.41,67.12c25.91-11.39,46.03-34.8,46.03-62.78C169.28,26.93,154.07,7.61,128.69,7.61 M332.38,250
      c-13.92,28.96-33.88,67.83-72.73,67.83c-20.4,0-52.28-21.8-82.17-60.69c-20.89,27.96-55.26,60.69-94.14,60.69
      c-44.33,0-83.18-37-83.18-79.19c0-44.14,45.49-85.72,81.86-105.18c-13.46-20.4-29.04-41.4-29.04-66.08C52.97,29.43,85.35,0,124.69,0
      c34.37,0,56.79,20.41,56.79,52.21c0,33.68-21.05,54.29-49.95,70.9c22.9,31.34,43.31,58.79,67.72,89.15
      c16.94-20.39,42.98-59.49,73.86-59.49c16.93,0,31.39,10.45,31.39,30.84c0,15.65-10.98,26.07-27.91,26.07
      c-15.42,0-23.37-11.39-23.37-22.77c0-8.53-1.51-11.83-5.01-11.83c-4.48,0-25.91,21.33-41.83,46.45
      c24.9,30.38,52.78,62.15,80.17,62.15c19.44,0,33.52-26.61,38.03-36.58L332.38,250z"/>
    </svg>
)

Logo.defaultProps = {
  color: "white",
}

export default Logo
